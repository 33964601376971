@font-face {
    font-family: 'AvenirLTStd-Book';
    src: url('/assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.woff') format('woff'),
    url('/assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.woff2') format('woff2'),
    url('/assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.eot'),
    url('/assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.otf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTStd-Roman';
    src: url('/assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.woff') format('woff'),
    url('/assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.woff2') format('woff2'),
    url('/assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.eot'),
    url('/assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.otf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'AvenirLTStd-Black';
    src: url('/assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.woff') format('woff'),
    url('/assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.woff2') format('woff2'),
    url('/assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.eot'),
    url('/assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.otf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400&display=swap');
