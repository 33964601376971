::selection {
    background: #4255F2;
}

.drop-down,
.drop-down:hover
.drop-down:active
.drop-down:focus {
background: none;
border: none;
height: 100%;
}
.address-section{
    background: #F7F7F7;

}
.garrafones-pic-carousel{
     background-position: -45;
    
}
.ubication-icon{
    width: 47px;
    height: 46px;
    background-size: 45px;
}
.bg-circulo{
        z-index: 4;
        width: 150px;
        height: 70px;
        position: absolute;
        background-position: 6px;
        margin-left: 959px;
        margin-top: 736px;
    
}

.custumer-1-image{
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: -10px;
    background-size: 254px;

}
.custumer-image{
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: -70px;
    background-size: 374px;

}
.circule-video{
    width: 157px;
    overflow: hidden;
    height: 126px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 161px;
    position: absolute;
    margin: -165px;
    left: auto;
    bottom: 0px;
    top: 945px;
    right: 35% !important;
    z-index: -1;

}
.circule-grey-video{
    width: 157px;
    overflow: hidden;
    height: 126px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 127px;
    position: absolute;
    margin: -165px;
    left: auto;
    bottom: 0px;
    top: 1201px;
    right: 25% !important;
    z-index: -1;

}
.circule-left-comments-video{
   
    width: 210px;
    overflow: hidden;
    height: 326px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 187px;
    position: absolute;
    margin: -163px;
    bottom: 7px;
    left: 165px;

}
.circule-left-comments {
    width: 157px;
    overflow: hidden;
    height: 227px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 161px;
    position: absolute;
    margin: -165px;
    bottom: 0px;
    top: 802px;
    left: 121px;
 

}
.section-services{
    position: relative;
    overflow: hidden;
}
.circules-left-services{
    width: 72px;
    height: 144px;
    /* border: 1px solid; */
    position: absolute;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 86px;
    left: 20%;
    bottom: 0px;
    top: 10%;
    
}
.circule-right-services{
    width: 115px;
    height: 227px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 152px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
}

.circules-right-comments{
    width: 157px;
    
    overflow: hidden;
    height: 227px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 161px;
    position: absolute;
    margin: -165px;
    left: auto;
    bottom: 0px;
    top: 85%;
    right: 25% !important;
    z-index: 0;

}
.coments{
    margin: -248px 40px;
    margin-bottom: 0px;
    padding: 35px;
    z-index: 0;
    position: relative;
        
        
}



.video-youtube{
    overflow: hidden;
    border-radius: 35px;
}
.background-video{
    background-position-y: -50px;
    background-position: -97px;

}

.background-custumer{
    background-position: -30px;
    background-position-y: -114px;
}

.section-custumers{
    margin-left: 90px;
}
.section-offer{
    z-index: -1;
}
.bg-circulo{
    z-index: 2;
    width: 150px;
    height: 70px;
    position: absolute;
    background-position: 6px;
    margin-left: 959px;
    margin-top: 736px;
}
.entrega-preferente{
       /* border: 1px solid red; */
       background-position-y: 0;
       background-position-x: 0;
       background-size: 253px;
       width: 254px;
       /* position: absolute; */
       height: 121px;
       /* margin-top: -61px;
       margin-left: -61px; */
}
.entrega-express{
    background-position-y: 0;
    background-position-x: 0;
    width: 230px;
    /* position: absolute; */
    height: 124px;
    /* margin-top: -61px;
    margin-left: -61px; */

}

.section-services{
    background-position-y: buttom;
    background-position: 91px;
}
.bg-carousel{
    background-position-y: -450px;
    
}
.button-bg-blue,
.button-bg-blue:hover{
    /* bg-gradient-to-r from-[#6F8EBE] to-[#61BABB]  */
    @apply rounded-[10px] h-[50px] bg-[#081C46] border-none  ;
    color: #FFFFFF;
}
.bg-initial-section{
    background-position-y: 297px;
    background-repeat: no-repeat;
    position: relative;
}
.initial-section{
   
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: cover;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px; 
    background-position-x: 17px;
   
    
}

.bg-movil{
    background-size: 588px;
    background-position-x: -202px;
    background-position-y: -27px;
    background-repeat: no-repeat;;
    right: 0px;
    bottom: 0px;
    top: 0px; 
}
.logo-footer{
    background-size: 118px;

}
.header-initial{
    /* background: url("/assets/imgs/bg-header.svg") no-repeat; */
    background-color: #FFFFFF;
    @apply bg-cover bg-center ;

    width: 100%;
    
}
.button-header{
    @apply rounded-[5px] bg-[#081C46]  border-hidden  ;
    color: #FFFFFF;
    
}
.button-header:hover{
    @apply rounded-[5px] bg-[#081C46]  border-hidden  ;
    color: #FFFFFF;
    
}
html,
body {
    font-family: 'Catamaran', sans-serif;
}

.regular {
    /* Catamaran Regular = 400 */
    font-weight: 300;
    font-family: Catamaran, "Open Sans", Helvetica, Arial, sans-serif;
}
.font-regular{
   
    font-family: 'Catamaran-Regular-400', sans-serif;

}
.font-normal {
    font-family: 'Catamaran-Thin 100', sans-serif;
}

.font-medium {
    font-family: 'Catamaran-ExtraLight 200', sans-serif;
}

.font-black{
    font-family: 'Catamaran-Thin 100', sans-serif;
}
.font-text{
    font-family: 'Catamaran', sans-serif;
    font-weight: 300;
    font-style: light !important;    
}
.font-title{
    font-family: 'Catamaran', sans-serif;
    font-weight: 100;
    
}


.address-cover .background {
   /* background: url("/assets/imgs/bg-main.svg") no-repeat;*/  
    background-position: -94px;

    background-size: 620px;
    background-position-y: 150px;
}

.schedule-section {
    background-position-x: -1050px;
    background-position-y: 50px;
    padding-bottom: 10px;
    width: 100%;
}

@media screen and (min-width: 640px) {
    .schedule-section {
        background-position-x: center;
        background-position-y: bottom;
        background-size: cover;
    }

    .address-cover .background {
        background-size: cover;
        background-position-y: 130px;
    }
}

#nprogress .bar {
    background: #784CE2;
}

.product-tag-item {
    @apply bg-gray-300 rounded-full w-8 h-8 grid place-content-center cursor-pointer;
}

.product-tag-item.selected {
    background: #26D684;
}

.btn-up,
.btn-down {
    @apply border-0;
}
/* .react-multi-carousel-list{
    @apply  md:overflow-hidden;
    
} */
.react-multi-carousel-item {
    @apply  w-[340px]  md:w-[340px] !important ;
    border: 1px solid #BABABA;
    border-radius: 13px;
   margin-left: 1px ;
   margin-right: 1px;
    display: flex;
    justify-content: center;
}

.react-multiple-carousel__arrow--left {
    left: 0;
}

.react-multiple-carousel__arrow--right {
    right: 0;
}

.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
    /* background: rgba(0, 0, 0, 0.3); */
    background: #021C49;
}

.react-multiple-carousel__arrow::before {
    /* color: black; */
    color: #FFFFFF;

}

.pop-btn {
    height: 60px;
}
