.ant-layout.main-layout,
.ant-layout-header {
    @apply h-[64px] ;   
}
.ant-input,
.ant-input:hover{
    /* @apply border-[#50B0AE]; */
}

.ant-dropdown-menu-title-content>a{
    color: #021C49;
    font-weight: bold;

}


.react-player {

    border-radius: 29px;
  }
.ant-layout-header {
    @apply px-0;

}
.react-multiple-carousel__arrow{
    z-index: 0;
}
.grf-input-phone {
    @apply rounded-lg py-0;
}

.grf-input-phone .ant-input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.grf-input-phone .ant-input,
.ant-picker-large {
    @apply h-[43px];
}

.ant-input.ant-input-lg {
    @apply h-[43px] rounded-lg text-gray-select-500;
}

.ant-picker-large {
    @apply w-full rounded-lg text-gray-select-500;
}

.ant-picker-large .ant-picker-input > input,
.ant-input.ant-input-lg,
.ant-picker-large {
    @apply font-medium text-base md:text-sm;
}

.ant-select.grf-select {
    @apply font-medium w-full text-base md:text-sm;
}

.ant-select.grf-select:not(.ant-select-customize-input) .ant-select-selector {
    /* border-color: #50B0AE; */
    @apply h-[43px] flex items-center font-medium rounded-lg;
}
/* .react-multi-carousel-list{
        @apply md:w-[700px];
       
} */

.icon-header{
    background: none;
    border-style: none;
}

.ant-btn-primary.secondary {
    border: 1px solid #c1c1c1;
    background: white;
    color: #343434;
}
.ant-btn-primary[disabled],
.ant-btn-sm{
    @apply h-[43px] text-base;
}

.ant-btn-primary.secondary:hover, .ant-btn-primary.secondary:active, .ant-btn-primary.secondary:focus {
    background: white;
}



.ant-btn-link:hover,
.ant-btn-link:focus {
    @apply text-violet-700 bg-transparent;
}


.ant-select .ant-select-selector .ant-select-selection-item {
    @apply text-gray-select-500 font-medium;
}

/* Component day-selector */
.ant-checkbox-group.day-selector {
    @apply flex gap-y-4 gap-x-4 flex-wrap justify-around;
}

.ant-checkbox-group.day-selector .ant-checkbox-wrapper {
    @apply h-[43px] mr-0 bg-white border border-gray-textbox-500 rounded-lg text-gray-value-444 font-medium px-10 md:px-14 flex items-center text-base md:text-sm;
}

.ant-checkbox-group.day-selector .ant-checkbox-wrapper:hover {
    /* @apply border-[#50B0AE]; */
}

.ant-checkbox-group.day-selector .ant-checkbox-wrapper-checked {
    @apply  bg-[#081C46] text-white pt-1;
    /* @apply  bg-gradient-to-r from-[#6F8EBE] to-[#61BABB] text-white pt-1; */
}

.ant-checkbox-group.day-selector .ant-checkbox-wrapper-disabled:hover {
    @apply border-gray-textbox-500;
}

.ant-checkbox-group.day-selector .ant-checkbox-wrapper .ant-checkbox,
.ant-checkbox-group.day-selector .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled.ant-checkbox-group-item {
    display: none;
}

/* Schedule radio: component for schedule options */
.ant-radio-group.grf-radio-group {
    @apply rounded-lg grid grid-cols-2 gap-y-3 gap-x-4 md:grid-cols-3  ;
}

.ant-radio-group.grf-radio-group .ant-radio-button-wrapper {
    @apply h-[43px] rounded-lg border-l grid place-content-center text-gray-value-444 font-medium text-base md:text-sm;
}

.ant-radio-group.grf-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.ant-radio-group.grf-radio-group .ant-radio-button-wrapper.schedule-selected {
    @apply bg-[#081C46];
    /* @apply bg-gradient-to-r from-[#6F8EBE] to-[#61BABB]; */
}

.ant-radio-group.grf-radio-group .ant-radio-button-wrapper.schedule-selected span {
    @apply text-white;
}

.ant-radio-group.grf-radio-group .ant-radio-button-wrapper:hover {
    /* @apply border-[#50B0AE]; */
}

.ant-modal-confirm .ant-modal-body {
    @apply pb-0  ;
}

.ant-message-custom-content {
    @apply flex;
}

.ant-result.confirm-view {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-result.confirm-view .ant-result-title {
    line-height: normal;
    font-size: 18px;
    margin-bottom: 16px;
}

.ant-result.confirm-view  {
    padding-left: 30px;
    padding-right: 30px;
}

.ant-message .anticon {
    height: 16px;
    width: 16px;
}

.text-white{
    display:initial;
    
}
.btn-black {
    height: auto;
    @apply rounded-full  bg-[#081C46]  border-[#7188BE];
    /* @apply rounded-full  bg-gradient-to-r from-[#6F8EBE] to-[#61BABB]  border-[#7188BE]; */
}
.btn-black:hover {
    height: auto;
    @apply rounded-full  bg-[#081C46]  border-[#7188BE];
    /* @apply rounded-full  bg-gradient-to-r from-[#6F8EBE] to-[#61BABB]  border-[#7188BE]; */
}

.ant-table-thead > tr > th {
    background: white;
}

.btn-whatsapp,
.btn-phone {
    @apply fixed block h-[60px] w-[60px] grid place-content-center rounded-full;
}

.btn-whatsapp {
    @apply bottom-[150px] right-[24px] bg-[#00d660];
}

.btn-phone {
    @apply bottom-[75px] right-[24px] bg-[#4255f2];
}

.ant-modal-content{
    border-radius: 1.25rem
}

.ant-modal.full-loader .ant-modal-content {
    background: transparent;
    box-shadow: none;
}

.loader-animation {
    animation: 1s linear 0s infinite normal none running rotate;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.ant-table-wrapper.billing .ant-table-title {
    padding-bottom: 0;
}

.ant-card{
    margin:1rem 0;
}
.ant-card-bordered{
    
    border-radius: 15px ;
    border: 1px solid rgba(0,0,0,.09);
}
.card-indicators{
   @apply border-none ;
}
.Card-info-indicator{
    @apply shadow-md;
}

.report-page iframe {
    width: 100%;
    height: calc(100vh - 90px);
  }
  
  .report-page .center-screen {
    display: grid;
    width: 100%;
    height: calc(100vh - 90px);
    place-content: center;
  }

  .button-pdf{
   @apply  bg-blue-500
   hover:bg-blue-600
   text-white
   font-bold
   py-2
   px-4
   rounded
  }

  .ant-btn-icon-only{
    @apply rounded-[8px] opacity-[0.8] border-none
  }